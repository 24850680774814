


import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/shared/dtos/login";
import { UtilsString } from "@/utils/utils-string";
import { RouterNames } from "@/router/routernames";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class LoginFormulario extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;

  public login!: Login;
  public hide: boolean = true;
  public visible_iniciarSesion = false;
  public visible_cuenta_caducada = false;
  public link_pago: string = "";

  public async mounted() {
    this.login = new Login();
    setTimeout(() => (this.hide = false), 1);

    if (this.$route.params.registro != undefined) {
      const registrostestweb = await import(
        "@/store/modules/registrostestweb-module"
      );
      this.login.UserName = registrostestweb.default.registro.email;
      this.login.Pwd = registrostestweb.default.registro.contrasena;
      this.visible_iniciarSesion = true;
    }
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.tryLogin();
      }
    });
  }

  public async tryLogin() {
    const authorizationService = await import(
      "@/shared/services/AuthorizationService"
    );
    authorizationService.authorizationService
      .login(this.login)
      .then(this.loginResult.bind(this));
  }

  private async loginResult(loginOk: any) {
    if (loginOk.Success) {
      this.hide = true;
      setTimeout(() => this.routeToMain(), 250);
    } else {
      const ssmMessageService = await import(
        "@/shared/services/message-service"
      );
      if (UtilsString.IsNullOrWhiteSpace(loginOk.Message)) {
        loginOk.Message = "Credenciales no validas";
      }
      ssmMessageService.ssmMessageService.toast(
        loginOk.Message,
        ssmMessageService.ssmMessageService.TypeError
      );
      if (loginOk.Message === "Su licencia ha caducado") {
        this.link_pago = loginOk.Token;
        console.log(loginOk);
        this.visible_cuenta_caducada = true;
      }
    }
  }

  private async Registrarse() {
    const routernames = await import("@/router/routernames");
    this.$router.push({ name: routernames.RouterNames.register });
  }
  private async LostPwd() {
    const routernames = await import("@/router/routernames");
    this.$router.push({ name: routernames.RouterNames.pwdlost });
  }

  private routeToMain() {
    this.$router.push("/");
  }
  private ver_licencias() {
    window.open("https://mymedicaldiet.es/licencias/?app_nutricionista");
  }
  private renovar_licencia() {
    window.open(this.link_pago);
    /*this.$router.push({
      name: RouterNames.renovar_subscripcion,
      params: { id: "420", correo: this.login.UserName.toString() },
    });*/
  }
}
